import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '企业信息管理'
    },
    component: Layout,
    redirect: '/licenseInfo',
    children:[
      {
        path: '/licenseInfo',
        meta: {
          title: '证照信息'
        },
        component: () => import('../views/enterpriseInfo/licenseInfo')
      },
      {
        path: '/baseInfo',
        meta: {
          title: '基本信息'
        },
        component: () => import('../views/enterpriseInfo/baseInfo')
      },
      {
        path: '/contactInfo',
        meta: {
          title: '签约信息'
        },
        component: () => import('../views/enterpriseInfo/contactInfo')
      },
      {
        path: '/ticketInfo',
        meta: {
          title: '发票信息'
        },
        component: () => import('../views/enterpriseInfo/ticketInfo')
      }
    ]
  },
  {
    path: '/account',
    name: 'account',
    meta: {
      title: '账户安全'
    },
    component: Layout,
    children:[
      {
        path: '/accountInfo',
        meta: {
          title: '账户安全'
        },
        component: () => import('../views/accountInfo/index')
      },
      {
        path: '/updateLoginPassword',
        meta: {
          title: '修改登录密码'
        },
        component: () => import('../views/accountInfo/updateLoginPassword')
      },
      {
        path: '/updateJyPwd',
        meta: {
          title: '修改交易密码'
        },
        component: () => import('../views/accountInfo/updateJyPwd')
      }
    ]
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    meta: {
      title: '用户管理'
    },
    component: Layout,
    children:[
      {
        path: '/userInfo',
        meta: {
          title: '用户信息'
        },
        component: () => import('../views/userInfo/index')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
