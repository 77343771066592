/*
 * @Descripttion: 
 * @version: 
 * @Author: xyh
 * @Date: 2021-04-08 10:00:38
 * @LastEditTime: 2021-07-23 10:44:30
 */
import { getAction,deleteAction,putAction,postAction} from '@/api/manage'

const queryPermissionsByUser = (params)=>getAction("/sys/permission/getUserPermissionByToken",params);

// 企业信息管理
// 获取用户登录信息
const getLoginInfo = () => getAction('/tax/client/user/centre/getLoginInfo');
// 获取营业执照信息
const getCompanyIndentifyInfo = (params => getAction(`/tax/admin/enterprise/getEnterpriseAuthentication/${params}`));
// 查询所有省份地区
const getSysRegion = () => getAction(`/tax/admin/enterprise/getSysRegion`);
// 查询企业基本信息
const enterpriseBaseInfo = (params)=> getAction(`/tax/client/user/centre/getEnterpriseBasicInfo/${params}`);
// 查询行业
const getSysIndustry = (params) => getAction('/tax/admin/enterprise/getSysIndustry', params);
// 获取发票抬头信息
const getEnterpriseInvoiceInfo = () => getAction('/tax/client/enterprise/receipt/getEnterpriseInvoiceInfo');
// 获取发票内容
const getBusinessScopes = (params) => getAction('/tax/client/enterprise/receipt/getBusinessScopes',params);
// 获取发票默认内容
const getDefBusinessScope = () => getAction('/tax/client/enterprise/receipt/getDefBusinessScope');
// 获取邮寄地址
const getInvoiceMailed = (params) => getAction('/tax/client/enterprise/receipt/getInvoiceMailed',params);
// 获取邮寄地址
const addInvoiceMailed = (params) => postAction('/tax/client/enterprise/receipt/addInvoiceMailed',params);
// 查询业务员
const getEmployee = (params) => getAction('/tax/admin/enterprise/getSalesman',params);
// 修改企业信息
const updateEnterpriseBaseInfo = (params)=>putAction(`/tax/admin/enterprise/updateEnterpriseBaseInfo`, params);
// 获取结算信息
const getEnterpriseBankInfo = () => getAction('/tax/client/enterprise/getEnterpriseBankInfo');
// 获取限额额度
const getQuotas = () => getAction('/tax/client/enterprise/sign/getQuotas');
// 获取费率
const getServiceRule = () => getAction('/tax/client/enterprise/sign/getRebatePoint');
// 获取合同
const getContractInfo = () => getAction('/tax/client/enterprise/sign/getContractInfo');
// 修改登录密码
const updateLoginPwd = (params) => putAction('/tax/client/enterprise/account/updatePwd',params);
// 修改交易密码
const updatePayPwd = (params) => postAction('/tax/client/user/payPassword/update',params);
// 重置交易密码
const resetPwd = () => postAction('/tax/client/user/payPassword/forget');
// 重置登录密码
const resetPassword = (params) => putAction(`/tax/admin/enterprise/account/userCentreResetPassword?loginId=${params}`)
const getUserBindEmail = () => getAction('/tax/client/user/getUserBindEmail');
// 获取用户信息列表
const getUserInfoList = (params) => getAction('/tax/client/enterprise/account/getEnterpriseAccounts2',params);
// 添加用户信息
const addEnterpriseAccount = (params) => postAction('/tax/client/enterprise/account/addEnterpriseAccount',params);
// 重置用户密码
const resetUserPwd = (params) =>putAction('/tax/client/enterprise/account/restPwd',params);
// 冻结
const freezeEnterpriseAccount = (params) => putAction('/tax/client/enterprise/account/freezeEnterpriseAccount',params);
// 解冻
const unLock = (params) => putAction('/tax/client/enterprise/account/unLock',params);
// 解绑微信
const unBindWxMq = (params) => getAction('/tax/client/user/unBindWxMq',params);
// 绑定微信
const getBindRQCode = (params) => getAction('/tax/client/user/getBindRQCode',params);
// 检查是否绑定微信
const checkBindWxMq = (params) => getAction('/tax/client/user/checkBindWxMq',params);
// 查询用户是否扫码绑定
const isChekSyS = (params) => getAction(`/tax/client/user/checkBind/${params}`)
//查询渠道企业logo配置等
const getEnterpriseChannelConfig = (params) => getAction('/tax/admin/enterprise/getEnterpriseChannelConfig/'+ params);
const restPwd2 = (params) => putAction('/tax/client/enterprise/account/restPwd2', params);

export {
  isChekSyS,
  checkBindWxMq,
  getBindRQCode,
  unBindWxMq,
  resetPassword,
  unLock,
  freezeEnterpriseAccount,
  resetUserPwd,
  addEnterpriseAccount,
  getUserInfoList,
  getUserBindEmail,
  resetPwd,
  updateLoginPwd,
  updatePayPwd,
  getQuotas,
  getServiceRule,
  getContractInfo,
  getEnterpriseBankInfo,
  updateEnterpriseBaseInfo,
  getEmployee,
  addInvoiceMailed,
  getInvoiceMailed,
  getDefBusinessScope,
  getBusinessScopes,
  getEnterpriseInvoiceInfo,
  getSysIndustry,
  enterpriseBaseInfo,
  getSysRegion,
  getLoginInfo,
  getCompanyIndentifyInfo,
  queryPermissionsByUser,
  getEnterpriseChannelConfig,
  restPwd2,
}