<template>
  <a-locale-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </a-locale-provider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import Vue from 'vue';
import { ACCESS_TOKEN } from "@/store/mutation-types";
export default {
  created () {
    let href = window.location.href.split('?')[1];
    if (href) {
      let token = href.split('=')[1];
      Vue.ls.set(ACCESS_TOKEN, token, 7 * 24 * 60 * 60 * 1000);
    }
  },
  data () {
    return {
      locale: zhCN,
    }
  },
}
</script>
<style>
#app{
  height: 100%;
}
*{
  box-sizing: border-box;
  font-family: '微软雅黑';
}
.table-search-submitButtons{
  display: flex;
  align-items: center;
  height: 40px;
}
</style>
