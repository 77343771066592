import api from './index'
import { axios } from '@/utils/request'

/**
 * @param parameter
 * @returns {*}
 */
export function login(parameter) {
  return axios({
    url: '/api/sys/login',
    method: 'post',
    data: parameter
  })
}
// 微信扫码
export function getWxCode(params) {
  return axios({
    url: '/tax/client/user/weiXin/getLoginRQCode',
    method: 'get',
    params
  })
}
// 校验是否扫码成功
export function isSuccessCode (params) {
  return axios({
    url: '/sys/checkLogin/' + params,
    method: 'get',
  })
}
export function wxLogin(parameter) {
  return axios({
    url: '/sys/pcLoginByWx',
    method: 'post',
    data: parameter
  })
}

export function getSmsCaptcha(parameter) {
  return axios({
    url: api.SendSms,
    method: 'post',
    data: parameter
  })
}

export function getInfo() {
  return axios({
    url: '/api/user/info',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function logout(logoutToken) {
  return axios({
    url: '/api/sys/logout',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-Access-Token':  logoutToken
    }
  })
}